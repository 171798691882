<template>
    <section class="wrapper profile" :class="{'participant-container' : !deputy}">
        <CommonBlock title="" @clickBack="clickBack" :visibleBackBtn="false" :cancelBackBtnEventPassed="false">
            <div class="description">
                Це твій особистий кабінет. Тут відображена інформація про тебе, твоя діяльність, твої досягнення, які будуть відображені в картках партійця.
            </div>
            <div class="img">
                <img v-if="deputy" src="../assets/images/cabinet-img.svg" alt="cabinet">
                <img v-if="!deputy" src="../assets/images/picture_PD.svg" alt="cabinet">
            </div>
        </CommonBlock>

        <article class="content custom-scroll">
            <form class="registartion-form" @submit.prevent="submitHandler">
                <div class="content-top">
                    <div class="avatar">
                        <label for="photo">
                            <img :src="user.photo" alt="" v-if="user.photo">
                        </label>
                        <input type="file" name="photo" id="photo" style="display: none!important;" @change="onFileChange">
                    </div>
                    <h3 class="title">{{user.first_name}} {{user.middle_name}}</h3>
                    <CustomButton label="Контроль" class="control-btn" @click.prevent="$router.push({name: 'activity'})" v-if="isDeputy()"/>
                </div>

                <div class="tabs-container" v-if="!deputy">
                    <router-link to="/encouragement/1">
                        <div class="tab-item">Система заохочень</div>
                    </router-link>
                    <router-link to="/add-friends">
                        <div class="tab-item">Запросити друзів</div>
                    </router-link>
                    <button class="achievement-btn" @click="$router.push({ name: 'achievements' })" type="button">
                        <div class="tab-item">Мої досягнення</div>
                    </button>
                </div>

                <input type="hidden" name="gender" v-model="user.gender">
                <input type="hidden" name="is_deputy" v-model="user.is_deputy">
                <input type="hidden" name="is_party_member" v-model="user.is_party_member">
                <div class="registartion-form_inner">
                    <div class="registartion-form_group">
                        <InputComponent
                            v-model="user.first_name"
                            label="Ім’я"
                            name="first_name"
                            required
                            :error="errors.first_name"
                        />
                        <InputComponent
                            v-model="user.last_name"
                            label="Прізвище"
                            name="last_name"
                            required
                            :error="errors.last_name"
                        />
                        <InputComponent
                            v-model="user.middle_name"
                            label="По-батькові"
                            name="middle_name"
                            required
                            :error="errors.middle_name"
                        />
                        <div class="registartion-form_field d-flex">
                            <div class="col">
                                <DatepickerComponent
                                    label="Дата народження"
                                    :error="errors.birth_date"
                                    required
                                    name="birth_date"
                                    v-model="user.birth_date"
                                />
                            </div>
                            <div class="col">
                                <CustomSelectV2
                                    id="sex"
                                    label="Стать"
                                    required="true"
                                    v-model="user.gender"
                                    :error="errors.gender"
                                    :options="[{label: 'Чоловіча', value: 0}, {label: 'Жіноча', value: 1}]"
                                />
                            </div>
                        </div>
                        <InputComponent
                            v-if="!deputy"
                            v-model="user.card_id"
                            label="Номер партійного квитка"
                            name="card_id"
                            autocomplete="off"
                            required
                            :error="errors.card_id"
                        />
                        <InputComponent
                            v-if="!deputy"
                            v-model="user.party_position"
                            label="Посада"
                            name="party_position"
                            :error="errors.party_position"
                        />
                        <div v-if="!deputy">
                            <CustomSelectV2
                                label="Вид діяльності"
                                :options="activityList"
                                v-model="user.job_type_id"
                                name="job_type_id"
                            />
                        </div>
                        <InputComponent
                            v-if="deputy"
                            v-model="user.education"
                            label="Освіта"
                            name="education"
                            :error="errors.education"
                        />

                        <InputComponent
                            v-if="deputy"
                            v-model="user.register_address"
                            label="Місце проживання (реєстрації)"
                            name="register_address"
                            :error="errors.register_address"
                        />
                        <InputComponent
                            v-if="deputy"
                            v-model="user.actual_address"
                            label="Місце фактичного проживання"
                            name="actual_address"
                            :error="errors.actual_address"
                        />
                        <InputComponent
                            v-if="deputy"
                            v-model="user.email"
                            label="Електронна адреса"
                            name="email"
                            type="email"
                            :error="errors.email"
                        />

                        <div class="phone-column" :class="{deputy: 'deputy-phone-column'}">
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.phone"
                                    label="Номер телефону"
                                    name="phone"
                                    type="tel"
                                    required
                                    :error="errors.phone"
                                />
                            </div>
<!--                            <div class="input" v-if="deputy">-->
<!--                                <input v-model="hidePhone" id="toggleNumber1" type="checkbox">-->
<!--                                <input type="hidden" v-model="hidePhone" name="hide_phone">-->
<!--                                <label for="toggleNumber1">приховати</label>-->
<!--                            </div>-->
                        </div>
                        <div class="registartion-form_field d-flex">
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.password"
                                    label="Ваш пароль"
                                    name="password"
                                    type="password"
                                    :error="errors.password"
                                />
                            </div>
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.password_repeat"
                                    label="Підтвердіть пароль"
                                    name="password_repeat"
                                    type="password"
                                    :error="errors.password_repeat"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="registartion-form_group">
                        <InputComponent
                            v-if="deputy"
                            v-model="user.card_id"
                            label="Номер партійного білету"
                            name="card_id"
                            autocomplete="off"
                            required
                            :error="errors.card_id"
                        />
                        <div class="phone-column">
                            <InputComponent
                                v-if="!deputy"
                                v-model="user.phone"
                                label="Номер телефону"
                                name="phone"
                                type="tel"
                                required
                                :error="errors.phone"
                            />
<!--                            <div class="input" v-if="!deputy">-->
<!--                                <input v-model="hidePhone" id="toggleNumber" type="checkbox">-->
<!--                                <input type="hidden" v-model="hidePhone" name="hide_phone">-->
<!--                                <label for="toggleNumber">приховати</label>-->
<!--                            </div>-->
                        </div>
                        <InputComponent
                            v-if="!deputy"
                            v-model="user.email"
                            label="Електронна адреса"
                            name="email"
                            type="email"
                            :error="errors.email"
                        />
                        <InputComponent
                            v-if="!deputy"
                            v-model="user.instagram"
                            label="Посилання на соц мережі"
                            name="instagram"
                            :error="errors.instagram"
                        />
                        <InputComponent
                            v-if="!deputy"
                            v-model="user.education"
                            label="Освіта"
                            name="education"
                            :error="errors.education"
                        />
                        <InputComponent
                            v-if="!deputy"
                            label="Місце роботи"
                            name="work"
                            v-model="user.work"
                        />
                        <div v-if="!deputy">
                            <CustomSelectV2
                                label="Партійна організація"
                                :options="partyOrganizationList"
                                v-model="user.party_organization_id"
                                name="party_organization_id"
                                searchable="true"
                            />
                        </div>
                        <div class="registartion-form_field">
                            <CustomSelectV2
                                v-if="deputy"
                                name="cadence"
                                label="Каденція"
                                v-model="user.cadence"
                                :error="errors.cadence"
                                :options="[{label: 'Вперше', value: 0}, {label: 'Повторна каденція', value: 1}]"
                            />
                        </div>
                        <InputComponent
                            v-if="deputy"
                            v-model="user.commission"
                            label="Постійна комісія"
                            required
                            name="commission"
                            :error="errors.commission"
                        />
                        <InputComponent
                            v-if="deputy"
                            v-model="user.rada_position"
                            label="Посада в раді"
                            name="rada_position"
                            :required="user.is_deputy"
                            :error="errors.rada_position"
                        />
                        <InputComponent
                            v-if="deputy"
                            v-model="user.party_position"
                            label="Посада в партії"
                            name="party_position"
                            :error="errors.party_position"
                        />
                        <InputComponent
                            v-if="deputy"
                            v-model="user.facebook"
                            label="Facebook"
                            name="facebook"
                            :error="errors.facebook"
                        />
                        <InputComponent
                            v-if="deputy"
                            v-model="user.twitter"
                            label="Twitter"
                            name="twitter"
                            :error="errors.twitter"
                        />
                        <div class="registartion-form_field d-flex" style="margin-bottom: 0;">
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.instagram"
                                    label="Instagram"
                                    name="instagram"
                                    :error="errors.instagram"
                                />
                            </div>
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.youtube"
                                    label="YouTube"
                                    name="youtube"
                                    :error="errors.youtube"
                                />
                            </div>
                        </div>
                        <InputComponent
                            v-if="deputy"
                            v-model="user.viber"
                            label="Viber"
                            name="viber"
                            type="tel"
                            placeholder="+"
                            :error="errors.viber"
                        />
                        <div class="registartion-form_field d-flex">
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.telegram"
                                    label="Telegram"
                                    name="telegram"
                                    type="tel"
                                    placeholder="+"
                                    :error="errors.telegram"
                                />
                            </div>
                            <div class="col">
                                <InputComponent
                                    v-if="deputy"
                                    v-model="user.whatsapp"
                                    label="Whatsapp"
                                    name="whatsapp"
                                    type="tel"
                                    placeholder="+"
                                    :error="errors.whatsapp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-group">
                    <CustomButton label="Скасувати" class="cancel-btn" theme="dark" @click.prevent="cancelSubmit"/>
                    <CustomButton label="Зберегти"/>
                </div>
            </form>
            <form action="/api/user/logout" method="post" class="logout">
                <button class="logout-btn">Вийти з акаунту</button>
            </form>
        </article>
    </section>
</template>

<script>
import CustomButton from "../components/CustomButton";
import CommonBlock from "../components/CommonBlock";
import InputComponent from "../components/ui/InputComponent";
import CustomSelect from '../components/CustomSelect'
import UserService from "../services/UserService";
import vSelect from "vue-select";
import CustomSelectV2 from "../components/ui/CustomSelectV2";
import CouncilService from "../services/CouncilService";
import RegionService from "../services/RegionService";
import DatepickerComponent from "../components/ui/DatepickerComponent";

export default {
    name: 'cabinet',
    data() {
        return {
            disabled: false,
            requireFields: [],
            user: {},
            errors: {},
            previewImage: null,
            image: null,
            councils: [],
            regions: [],
            areas: [],
            hovering: false,
            deputy: this.$store.getters.identity.is_deputy,
            activityList: [],
            activityId: null,
            partyOrganizationList: [],
            organizationId: null,
            hidePhone: 0,
        }
    },
    components: {
        CustomButton,
        CommonBlock,
        InputComponent,
        DatepickerComponent,
        vSelect,
        CustomSelectV2,
        CustomSelect,
    },
    created() {
        this.user = Object.assign({}, this.$store.getters.identity);

        this.organizationId = this.party_organization_id
        this.activityId = this.job_type_id

        this.user.cadence = this.user.cadence || 0;
        this.setCouncils();
        this.setRegions();
        this.getActivityList();
        this.getOrganizationList();

        if(this.user.area_id) {
            this.setAreas(true);
        }
    },
    watch:{
      hidePhone(){
          if (this.hidePhone){
              this.hidePhone = 1
          } else {
              this.hidePhone = 0
          }
      }
    },
    methods: {
        clickBack() {
            this.$router.go(0)
        },
        async setCouncils() {
            let councils = await CouncilService.get();
            this.councils = councils.map(council => {
                return {
                    value: +council['key'],
                    label: council['value']
                };
            });
        },
        isDeputy() {
            return this.$store.getters.identity.rada_id && this.$store.getters.identity.is_deputy;
        },
        async setRegions() {
            let regions = await RegionService.get([1]);
            this.regions = regions.results.map(region => {
                return {
                    value: +region['id'],
                    label: region['text']
                };
            });
        },
        async setAreas(cancelResetArea=false) {
            if(cancelResetArea !== true) {
                this.user.area_id = null;
            }
            let areas = await RegionService.get([3,4,5,6], this.user.region_id);
            this.areas = areas.results.map(area => {
                return {
                    value: +area['id'],
                    label: area['text']
                };
            });
        },
        cancelSubmit(e) {
            this.user = Object.assign({}, this.$store.getters.identity);
        },
        async submitHandler(e) {
            let resp = await UserService.update(new FormData(e.target)).catch(err => {
                if(err.data) {
                    this.errors = err.data;
                }
                setTimeout(() => {
                    let errorLabels = document.getElementsByClassName('error-label');

                    if(errorLabels[0]) {
                        errorLabels[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    }
                }, 100);
            });

            if(resp && resp.status === 'success') {
                this.$notify({
                    text: 'Успішно збережено!',
                    type: 'success',
                });
                this.errors = {};
            }
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();

            reader.onload = function (e) {
                this.user.photo = e.target.result;
            }.bind(this);
            reader.readAsDataURL(file);
        },
        async getActivityList(){
            let response = await UserService.getActivityList()
            response.forEach(item => {
                this.activityList.push(
                    {"label": item.name, 'value': item.id}
                )
            })
        },
        async getOrganizationList(){
            let response = await UserService.getPartyOrganization()
            response.forEach(item => {
                this.partyOrganizationList.push(
                    {"label": item.name, 'value': item.id}
                )
            })
        }
    }
}
</script>


<style lang="less" scoped>
.control-btn{
    min-width: 220px;
    margin: 0 0 0 auto!important;
    @media all and(max-width: 600px) {
        margin: 25px auto 0!important;
    }
}

form{
    max-width: 1040px;

}

.img {
    display: flex;
    height: 100%;
    align-items: center;
    img{
        max-width: 307px;
        width: 100%;
        height: auto;
    }
}

.text {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.14px;
    color: #fff;
}
.input-field {
    margin-bottom: 20px;
}

.phone-column{
    display: flex;
    align-items: center;
    .form-input{
        width: 100%;
    }
    &.deputy-phone-column{
        .col{
            flex: 1 1 auto;
        }
    }
    .input{
        display: flex;
        align-items: center;
    }
    input{
        margin: 0 20px;
        width: 20px;
        height: 20px;
    }
    label{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #1a2744;
    }
}

.form-inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}

.form-group {
    width: 45%;
    justify-content: center;
}

.content-top {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    border: 0;
    @media all and(max-width: 950px) {
        margin: 40px 0;
    }
    @media all and(max-width: 600px) {
        display: block;
        text-align: center;
        .avatar{
            margin: 0 auto 20px;
        }
    }
}
.registartion-form_field{
    margin-bottom: 20px;
}

.title {
    font: 500 24px/1.33 @secondFontFamily;
    letter-spacing: -0.17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 40px;
    color: @blueColor;
    @media all and(max-width: 600px) {
        margin-right: 0;
        overflow: visible;
        white-space: normal;
    }
}

.logout {
    text-align: center;
    .logout-btn {
        color: @blueColor;
        font: 600 18px/1.33 @secondFontFamily;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        &:before {
            content: '';
            display: inline-block;
            width: 30px;
            height: 26px;
            margin-right: 20px;
            background: url(../assets/images/logout.svg) no-repeat center center;
            background-size: contain;
        }
    }
}

</style>

<style lang="less" scoped>

.avatar{
    margin-right: 40px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #ffc900;
        transition: opacity .15s linear;
        display: block;
        position: relative;
        z-index: 2;
        object-fit: cover;
    }
    label{
        cursor: pointer;
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: 0!important;
        &:hover{
            img{
                opacity: .4;
            }
            &:after, &:before{
                opacity: 1;
            }
        }
        &:after, &:before{
            content: '';
            display: block;
            position: absolute;
            transition: opacity .15s linear;
        }
        &:before{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 50%;
            z-index: 2;
            border: 2px dotted #CDD4E0;
        }
        &:after{
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #F5F5F5 url(../assets/images/camera.svg) center center no-repeat;
            background-size: 26px 26px;
        }
    }
}
.radio-field, .form-field, .radio-group {
    display: flex;
}

.registartion {
    .wrapper {
        display: flex;
        overflow-y: auto;
        max-width: 100%;
    }
    &-inner {
        width: 30%;
        height: 100vh;
        background: url(../assets/images/calendar-bg.jpg) no-repeat center center;
        background-size: cover;
        padding: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }

    &-title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.392px;
        color: #fff;
        margin-bottom: 144px;
    }

    &-img {
        width: 241px;
        height: 261px;
        margin-bottom: 29px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-description {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.14px;
        color: #fff;
        margin-bottom: 20px;
    }

    &-form {
        max-width: 1040px;
        margin: 0 auto;
        &_inner {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media all and(max-width: 768px) {
                display: block;
            }
        }
        .col{
            position: relative;
        }
        &_group {
            width: calc(50% - 40px);
            @media all and(max-width: 1200px) {
                width: calc(50% - 20px);
            }
            @media all and(max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }
        }

        button {
            margin: 0 auto;
            display: block;
        }
    }
}

// Header

.header {
    width: 100%;
}

.header-logo {
    // width: 253px;
    height: 51px;
    margin-bottom: 22px;
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
    }
}

/* breadcrumb */
.breadcrumb {
    list-style: none;
    margin-bottom: 45px;
}

.breadcrumb-item {
    display: inline;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.112px;
    color: #fff;
    margin-right: 20px;
}

.breadcrumb-link {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.112px;
    color: @yellowColor;
    text-decoration: none;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 15px;
        background: #fff;
        bottom: 1px;
        right: -11px;
    }
}

.btn-back {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFC900;
    text-decoration: none;

    &_icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #ffc900;
        box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
}

.d-flex {
    display: flex;
    justify-content: space-between;
    @media all and(max-width: 1350px) {
        display: block;
    }
}

.col {
    width: 48%;
    @media all and(max-width: 1350px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.avatar label {
    padding-left: 0;
}



@media screen and (max-width: 1920px) {
    .registartion {
        &-inner {
            max-width: 640px;
            width: 30%;
        }

        &-content {
            /* max-width: 1280px;*/
            width: 70%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .registartion {
        &-inner {
            padding: 40px 40px 40px 50px;
        }

        &-title {
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 40px;
        }

        &-description {
            font-size: 18px;
            line-height: 26px;
        }

        &-content {
            padding: 40px;
        }

        &-form_inner {
            margin-bottom: 30px;
        }
    }

    .breadcrumb {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .registartion-img {
        width: 140px;
        height: 140px;
    }

    .registartion-description {
        font-size: 14px;
        line-height: 18px;
    }

    .btn-back {
        font-size: 16px;
        line-height: 24px;
    }

    .btn-back_icon {
        width: 40px;
        height: 40px;

        img {
            width: 10px;
            height: 18px;
        }
    }

    .registartion-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
}

@media screen and (max-height: 700px) {
    .registartion-img {
        width: 140px;
        height: 140px;
    }

    .registartion-description {
        font-size: 14px;
        line-height: 18px;
    }

    .btn-back {
        font-size: 16px;
        line-height: 24px;
    }

    .btn-back_icon {
        width: 40px;
        height: 40px;

        img {
            width: 10px;
            height: 18px;
        }
    }

    .registartion-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
}

.participant-container{
    .content-top{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .tabs-container{
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px){
            flex-direction: column;
        }
        .achievement-btn{
            margin: 0;
        }
        .blue-btn{
            .tab-item{
                background-color: #1a2744;
            }
        }
        .tab-item{
            height: 50px;
            width: 260px;
            border-radius: 10px;
            box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
            background-color: #ffc900;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            font-size: 18px;
            font-family: @secondFontFamily;
            margin-right: 10px;
            @media (max-width: 1300px){
                width: auto;
                padding: 10px;
            }
            @media (max-width: 768px){
                width: 80vw;
                padding: 10px;
                margin: 5px;
            }
        }
    }
}
::v-deep{
    .common-block{
        @media (max-width: 950px){
            min-height: auto!important;
        }
        .title{
            display: none;
        }
        .description{
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.13px;
            color: #fff;
            font-family: @defaultFontFamily;
            margin-bottom: 22px;
            margin-top: -20px;
        }
    }

}

</style>

<style lang="less">

.registartion-form .btn-group {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @media screen and (max-width: 480px) {
        justify-content: space-between;
    }
    button{
        margin: 0 5px!important;
        @media screen and (max-width: 992px) {
            max-width: 180px;
        }
        @media screen and (max-width: 375px) {
            max-width: 44%;
        }
    }
    .cancel-btn{
        svg{
            transform: scaleX(1.2) rotate(180deg);
            g{
                fill: #cdd4e0;
            }
        }
        &:after{
            box-shadow: 0 24px 24px -14px #cdd4e080;
        }
    }
}

</style>

<style lang="less" scoped>
    .profile {
        display: flex;

        @media all and(max-width: 992px) {
            display: block;
        }

        .content{
            padding-top: 82px;
            @media all and (max-width: 950px) {
                padding-top: 0;
                overflow: hidden;
            }
        }

        .common-block {
            max-height: 100vh;

            .title{
                margin-top: 60px;
                margin: 40px 0 80px;
            }

            @media all and(max-width: 992px) {
                .title{
                    margin: 10px 0;
                }
                .img{
                    display: none;
                }
            }
            img{
                height: auto;
            }
        }

        .to-cabinet {
            display: none !important;
        }
    }
</style>
